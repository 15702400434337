import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Contact from '../components/Contact'

import Layout from '../components/Layout'
import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'
import Banners from '../components/Banners'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


export const IndexPageTemplate = ({
  banners,
  maincontent
}) => (
  <div>
    <Banners bannerItems={banners} classWrap="" />
    <Banners bannerItems={maincontent.blocks} /> 
    <Contact />
  </div>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        banners={frontmatter.banners}
        maincontent={frontmatter.maincontent}
      />
    </Layout>
  ) 
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        banners {
          image {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          link
          bgcolour
          description
        }
        maincontent {
          blocks {
            testimonial
            heading
            description
            link
            bgcolour
            classwrap
            image {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
